:root {
  --dark-green: #6B9080;
  --medium-green: #A4C3B2;
  --light-green: #CCE3DE;
  --lighter-green: #EAF4F4;
  --lightest-green: #F6FFF8;
}
/* App.css */
.App {
  overflow: hidden;
}
.section-wrapper {
  min-height: 1000px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--lightest-green);
  transition: background-color 0.3s ease;
  padding: 20px;
}

.section-wrapper.active {
  background-color: var(--lighter-green);
}
.site-wrapper.small {
  margin-top: 250px;
}

.shrink .header-wrapper {
  height: 150px;
}

.shrink .logo img {
  width: 100px;
  height: 100px;
}

.shrink .logo p {
  font-size: 16px;
}

.parallax-image {
  max-height: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-nav {
  background-color: var(--light-green);
}
