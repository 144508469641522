.nav button, .footer-wrapper button {
    appearance: none;
    border-width: 0;
    box-sizing: border-box;
    color: var(--dark-green);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1em;
    margin: 0;
    opacity: 1;
    outline: 0;
    padding: 1.5em 2.2em;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-rendering: geometricprecision;
    transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1), background-color 100ms cubic-bezier(.694, 0, 0.335, 1), color 100ms cubic-bezier(.694, 0, 0.335, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
    overflow: hidden;
}

.nav button:hover, .footer-wrapper button:hover {
    color: var(--lightest-green);
}

.nav button::before, .footer-wrapper button::before {
    content: "";
    position: absolute;
    top: var(--mouse-y, 50%);
    left: var(--mouse-x, 50%);
    width: 0;
    height: 0;
    background-color: var(--medium-green);
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s cubic-bezier(.165, 0.84, 0.44, 1);
    pointer-events: none; /* Ensure pseudo-element does not interfere with button functionality */
}

.nav button[data-enter-direction="left"]::before,
.nav button[data-enter-direction="right"]::before,
.nav button[data-enter-direction="top"]::before,
.nav button[data-enter-direction="bottom"]::before,
.footer-wrapper button[data-enter-direction="left"]::before,
.footer-wrapper button[data-enter-direction="right"]::before,
.footer-wrapper button[data-enter-direction="top"]::before,
.footer-wrapper button[data-enter-direction="bottom"]::before {
    transform: translate(-50%, -50%) scale(1);
    width: 300px;
    height: 300px;
}

.nav button[data-leave-direction="left"]::before,
.nav button[data-leave-direction="right"]::before,
.nav button[data-leave-direction="top"]::before,
.nav button[data-leave-direction="bottom"]::before,
.footer-wrapper button[data-leave-direction="left"]::before,
.footer-wrapper button[data-leave-direction="right"]::before,
.footer-wrapper button[data-leave-direction="top"]::before,
.footer-wrapper button[data-leave-direction="bottom"]::before {
    transform: translate(-50%, -50%) scale(0);
    width: 300px;
    height: 300px;
}

.nav button span, .footer-wrapper button span {
    z-index: 1;
    position: relative;
}
