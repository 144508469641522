.about-header {
  font-size: clamp(5rem, 20vw + 1rem, 20rem);
  margin-top: clamp(-140px, -10vw - 20px, -70px);
  color: var(--light-green);
  margin-bottom: 20px;
  opacity: 0.3;
  user-select: none;
}

.about-content-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-bottom: 100px;
}

.about-text-main {
  text-align: justify;
  color: black;
  max-width: 90%;
  font-size: clamp(2rem, 4vw + 1rem, 5.5rem);
}

.about-text-secondary {
  color: #525252;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-text-item {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-text-item-header {
  max-width: 80%;
  font-size: 2rem;
  font-weight: 600;
}

.about-text-item-content {
  max-width: 60%;
  font-size: 1.3rem;
}

@media (min-width: 1200px) {
  .about-header {
    margin-top: -140px; /* Fixed value for larger screens */
  }
}

@media (max-width: 1200px) {
  .about-text-main {
    max-width: 100%;
  }
  .about-text-secondary {
    flex-direction: column;
    gap: 20px;
  }

  .about-text-item-content {
    flex-direction: row;
    text-align: left;
    max-width: 100%;
  }

  .about-text-item {
    align-items: flex-start;
  }

  .about-header {
    margin-top: clamp(-60px, -10vw - 20px, -70px);
  }

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .about-text-item-header {
    max-width: 100%;
    font-size: 1.5rem;
  }

  .about-content-container {
    margin-bottom: 20px;
  }
}
@media (max-width: 576px) {

}

