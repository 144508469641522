/* Footer.css */
.footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--light-green);
    padding: 40px 20px;
    color: #333;
    position: relative; /* Ensure the buttons can be positioned absolutely */
}

.footer-links {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 20px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    width: 100%;
    margin-top: 80px; /* Ensure content doesn't overlap the buttons */
}

.footer-logo img {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--dark-green);
    width: 100%;
    margin-bottom: 40px;
}

.contact-form h2 {
    margin-bottom: 20px;
}

.contact-form .form-group {
    width: 100%;
    margin-bottom: 10px;
}

.contact-form .form-group label {
    margin-bottom: 5px;
    display: block;
}

.contact-form input,
.contact-form textarea {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form button {
    padding: 10px 20px;
    background: var(--dark-green);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
}

.contact-form button:hover {
    background: var(--medium-green);
}

.feedback-message {
    margin-top: 20px;
    color: var(--dark-green);
}

.footer-bottom {
    color: var(--dark-green);
    margin-top: 20px;
}

.footer-bottom p {
    margin: 0;
}
@media (max-width: 1200px) {
    .footer-links {
        position: relative;
        display: flex;
        align-items: center;
    }
}
