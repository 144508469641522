.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background: var(--light-green);
  z-index: 1000;
  transition: transform 0.3s ease;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-wrapper.small {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 50%;
}

.logo {
  width: 150px;
  height: 150px;
  transition: none;
}

.nav {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  width: 50%;
}

.nav button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.shrink .logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shrink .header-wrapper {
  height: auto;
  position: relative;
  top: 0;
  left: 0;
}

.shrink .logo {
  width: 60px;
  height: 60px;
}

@media (max-width: 1024px) {
  .header-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-top: 20px;
  }
  .logo-container {
    align-items: center;
  }
  .header-wrapper.small {
    gap: 0px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .nav {
    width: 100%;
    justify-content: center;
  }
  .nav button {
    padding: 10px !important;
    display: none;
  }
}
