.bebas-neue-regular {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.barlow-thin {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-extralight {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-light {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-regular {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-medium {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-semibold {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-bold {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-extrabold {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-black {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-thin-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-extralight-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-light-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-regular-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-medium-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-semibold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-bold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-extrabold-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-black-italic {
  font-family: "Barlow", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.barlow-condensed-thin {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.barlow-condensed-extralight {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.barlow-condensed-light {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.barlow-condensed-regular {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.barlow-condensed-medium {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.barlow-condensed-semibold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.barlow-condensed-bold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.barlow-condensed-extrabold {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.barlow-condensed-black {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.barlow-condensed-thin-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.barlow-condensed-extralight-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.barlow-condensed-light-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.barlow-condensed-regular-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.barlow-condensed-medium-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.barlow-condensed-semibold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.barlow-condensed-bold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.barlow-condensed-extrabold-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.barlow-condensed-black-italic {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 900;
  font-style: italic;
}
