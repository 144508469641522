/* Branding.css */

.branding-header {
  font-size: clamp(5rem, 20vw + 1rem, 20rem);
  margin-top: clamp(-140px, -10vw - 20px, -70px);
  color: var(--light-green);
  margin-bottom: 60px;
  opacity: 0.3;
}

.branding-content-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-top: 100px;
  margin-bottom: 200px;
  gap: 150px;
}

.branding-text {
  padding: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.branding-image-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  position: relative;
  margin-top: 70px;
}

.image-wrapper {
  overflow: hidden; /* Ensure images are contained within their boxes */
  width: 300px;
  height: 300px;
  transition: transform 0.3s ease-out; /* Smooth transition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-wrapper.top-left {
  border-top-left-radius: 10px;
}

.image-wrapper.top-right {
  border-top-right-radius: 10px;
}

.image-wrapper.bottom-left {
  border-bottom-left-radius: 10px;
}

.image-wrapper.bottom-right {
  border-bottom-right-radius: 10px;
}

.branding-image {
  width: 100%;
  height: 100%;
}

.branding-content-header {
  margin: 0;
  font-size: clamp(2rem, 5vw + 1rem, 4rem);
  max-width: 600px;
  margin-bottom: 50px;
}

.branding-content-text {
  font-size: 28px;
  max-width: 400px;
}

@media (min-width: 1200px) {
  .branding-header {
    margin-top: -140px; /* Fixed value for larger screens */
  }
}

@media (max-width: 1200px) {
  .branding-content-container {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .branding-image-container {
    grid-template-columns: 1fr;
  }

  .image-wrapper {
    width: 100%;
    height: auto;
  }

  .branding-header {
    margin-top: clamp(-70px, -10vw - 20px, -140px); /* Adjust margin-top for smaller screens */
  }

  .branding-content-text {
    max-width: 100%;
  }

  .branding-image {
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .branding-content-container {
    margin-top: 20px;
  }

  .branding-header {
    margin-top: clamp(-60px, -10vw - 20px, -70px);
    margin-bottom: 0;
  }

  .branding-image-container {
    margin-top: 0;
  }
}
