/* Development.css */
  .development-header {
    font-size: clamp(5rem, 20vw + 1rem, 20rem);
    margin-top: clamp(-140px, -10vw - 20px, -70px);
    color: var(--light-green);
    margin-bottom: 20px;
    opacity: 0.3;
  }

  .development-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    margin-bottom: 200px;
    gap: 150px;
  }

  .development-text {
    padding: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .development-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 200px;
    margin-bottom: 200px;
    gap: 150px;
  }

  .development-image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }

.development-content-header {
  margin: 0;
  font-size: clamp(2rem, 5vw + 1rem, 4rem);
  max-width: 600px;
  margin-bottom: 50px;
}

.development-content-text {
  font-size: 28px;
  max-width: 400px;
}

@media (min-width: 1200px) {
  .branding-header {
    margin-top: -140px; /* Fixed value for larger screens */
  }
}

@media (max-width: 1400px) {
  .development-content-container {
    flex-direction: column;
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .development-image-container {
    grid-template-columns: 1fr;
  }

  .image-wrapper {
    width: 100%;
    height: auto;
  }

  .development-header {
    margin-top: clamp(-70px, -10vw - 20px, -140px); /* Adjust margin-top for smaller screens */
  }
  .development-text {
    max-width: 100%;
  }
  .development-content-header {
    max-width: 100%;
  }
  .development-content-text {
    max-width: 100%;
  }

  .development-image {
    max-width: 600px;
  }
}
@media (max-width: 576px) {
  .development-content-container {
    margin-top: 20px;
  }

  .development-header {
    margin-top: clamp(-60px, -10vw - 20px, -70px);
    margin-bottom: 0;
  }
}
