/* Hero.css */

/* Default styles for larger screens */
.hero-wrapper {
  min-height: 1000px;
  width: 100%;
  background: url('../assets/hero.jpg') no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  color: white;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}

.hero-text {
  font-size: clamp(4rem, 10vw + 1rem, 10rem);
  width: 100%;
  margin-bottom: 20px;
}

.cta-button {
  padding: 15px 30px;
  background: linear-gradient(135deg, #fff, #e0e0e0);
  border: none;
  border-radius: 30px;
  color: black;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: background 0.3s, transform 0.3s;
  margin-top: 20px;
}

.cta-button:hover {
  background: linear-gradient(135deg, #e0e0e0, #fff);
}

.cta-button:link,
.cta-button:visited {
  color: black;
}

.tagline-container {
  position: relative;
  width: 100%;
}

.tagline {
  width: 100%;
}

/* Media queries for smaller screens */
@media (max-width: 1200px) {
  .hero-wrapper {
    background-size: cover;
  }

  .hero-content {
    width: 60%;
  }
}

@media (max-width: 992px) {
  .hero-wrapper {
    min-height: 800px;
    background-size: cover;
  }

  .hero-content {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .hero-wrapper {
    min-height: 700px;
    background-size: cover;
    background-position: center center; /* Crop the image in the middle */
  }

  .hero-content {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .hero-wrapper {
    min-height: 600px;
    background-size: cover;
    background-position: calc(50% - 150px) center;
  }

  .hero-content {
    width: 90%;
  }
}
